import React from "react";
import { PropTypes } from "prop-types";
import { Card, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { CiImport, CiExport } from "react-icons/ci";
import moment from "moment";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import PiDataTable from "views/DataTable/PiDataTable";

import {
  getPsvOrderDetailByOrderId,
  resetComponentStore,
  importPsvResultFromInstaknow,
} from "actions/psvOrderDetailActions";

import { pushOrderToInstaKnow } from "actions/psvOrderActions";

import DownloadPsvFile from "./DownloadPsvFile";

import { openBase64File } from "utils/downloadHelper";

const PsvOrderDetail = ({
  psvOrderInfo,
  psvOrderitemsList,
  getPsvOrderDetailByOrderId,
  loadingPsvOrderDetail,
  resetComponentStore,
  pushOrderToInstaKnow,
  importPsvResultFromInstaknow,
}) => {
  const { order_id } = useParams();

  const [onlyOnce, setOnce] = React.useState(true);
  const [importing, setImporting] = React.useState(false);
  const [pushing, setPushing] = React.useState(false);

  const onDownloadClickHandler = (OrderItem) => {
    const psv_result = OrderItem["psv_result"];

    const report = psv_result["reports"].length ? psv_result["reports"][0] : {};

    if (!report["base64"]) return;

    const base64 = report["base64"];
    const file_title = report["title"] ? report["title"] : "Report.pdf";

    openBase64File(base64, file_title, "application/pdf");
  };

  const columns = [
    {
      name: "PSV Items",
      selector: (row) => {
        const credDetail = row.credDetail ? row.credDetail : {};
        return (
          <React.Fragment>
            <span className="badge bg-info text-dark">{row.credType}</span>
            {row.credType === "SCHOOL" ? (
              <label>
                <span className="ml-1">
                  {credDetail.degree && credDetail.degree.degree
                    ? credDetail.degree.degree
                    : ""}
                </span>{" "}
                from{" "}
                <span>
                  {credDetail.school.name}, {credDetail.school.state}
                </span>
              </label>
            ) : row.credType === "CRED" ? (
              <label>
                <span className="ml-1">
                  {credDetail.credential.code} - {credDetail.state}
                </span>
              </label>
            ) : row.credType === "MCOO" ? (
              <label>
                <span className="ml-1">Opt Out of Medicare</span>
              </label>
            ) : row.credType === "NPI" ? (
              <label>
                <span className="ml-1">NPI</span>
              </label>
            ) : row.credType === "OIG" ? (
              <label>
                <span className="ml-1">OIG Exclusion</span>
              </label>
            ) : row.credType === "DEA" ? (
              <label>
                <span className="ml-1">DEA License</span>
              </label>
            ) : row.credType === "SAM" ? (
              <label>
                <span className="ml-1">SAM Exclusion</span>
              </label>
            ) : null}
          </React.Fragment>
        );
      },
      sortable: false,
      width: "55%",
    },
    {
      name: "Date Verified",
      selector: (row) => (
        <div className="py-1">
          {row.psv_result && row.psv_result.createdAt ? (
            <div title="PSV Verified At">
              <span>
                {moment(row.psv_result.createdAt).format("MM/DD/YYYY hh:mm a")}
              </span>
            </div>
          ) : null}
        </div>
      ),
      sortable: false,
      width: "15%",
    },
    {
      name: "Status",
      cell: (row) => {
        switch (row.status) {
          case 1:
            return "Open";
          case 2:
            return "Failed";
          case 3:
            return "Completed";
          default:
            return "";
        }
      },
      sortable: false,
      width: "15%",
    },
    {
      name: "",
      selector: (row) => (
        <React.Fragment>
          {row.status === 3 && row.psv_result ? (
            <Button
              variant="outline-primary"
              size="sm"
              title="Click to view report"
              onClick={() => onDownloadClickHandler(row)}
            >
              <FaEye />
            </Button>
          ) : null}
        </React.Fragment>
      ),
      sortable: false,
      width: "15%",
    },
  ];

  React.useMemo(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getPsvOrderDetailByOrderId(order_id);
  }, [getPsvOrderDetailByOrderId, resetComponentStore]);

  const handlerDownloadPSV = () => {
    if (!psvOrderInfo) return;

    const PSV_data = {
      OrderID: psvOrderInfo.orderID,
      ProviderID: psvOrderInfo.provider.providerID,
      CredentialCheckID: "",
      ProviderFirstName: psvOrderInfo.provider.firstName,
      ProviderMiddleName: psvOrderInfo.provider.middleName,
      ProviderLastName: psvOrderInfo.provider.lastName,
      ProviderTIN: psvOrderInfo.provider.providerTIN,
      NPI: psvOrderInfo.provider.npi,
      PrimaryStreetAddress: psvOrderInfo.provider.primaryStreetAddress,
      PrimaryCity: psvOrderInfo.provider.primaryCity,
      PrimaryState: psvOrderInfo.provider.primaryState,
      PrimaryZipCode: psvOrderInfo.provider.primaryZipCode,
      Schools: [],
      Credentials: [],
      Other: {
        CheckNPI: true,
        CheckOIG: true,
        CheckSAM: true,
        CheckABMS: true,
      },
    };

    if (psvOrderitemsList.length) {
      for (let item of psvOrderitemsList) {
        if (item.credType === "SCHOOL") {
          PSV_data["Schools"].push({
            SchoolName: item.credDetail.school.name,
            Degree: item.credDetail.degree.degree,
            YearAwarded: item.credDetail.year_awarded,
          });
        }

        if (item.credType === "CRED") {
          PSV_data["Credentials"].push({
            CredentialCode: item.credDetail.credential.code,
            State: item.credDetail.state,
            LicenseNumber: item.credDetail.license_number,
          });
        }
      }
    }

    const fileName = `${PSV_data.OrderID}_${PSV_data.ProviderID}`;
    return { PSV_data, fileName };
  };

  const handlerImportResults = () => {
    if (!psvOrderInfo) return;

    const confirmation = window.confirm(
      `Are you sure you want to import results from Instaknow for this order ${psvOrderInfo.orderID}?`
    );

    if (!confirmation) return;

    setImporting(true);
    // Import results from IK
    importPsvResultFromInstaknow(psvOrderInfo?._id).then((res) => {
      setImporting(false);
      if (res.status) {
        getPsvOrderDetailByOrderId(order_id);
      }
    });
  };

  const handlerPushOrder = () => {
    if (!psvOrderInfo) return;

    const confirmation = window.confirm(
      `Are you sure you want to push this order ${psvOrderInfo.orderID} to Instaknow?`
    );

    if (!confirmation) return;

    setPushing(true);
    // Push order to IK
    pushOrderToInstaKnow(psvOrderInfo?._id).then((res) => {
      setPushing(false);
      if (res.status) {
        getPsvOrderDetailByOrderId(order_id);
      }
    });
  };

  const providerInfo =
    psvOrderInfo && psvOrderInfo.provider.fullName
      ? `${psvOrderInfo.orderID} - ${psvOrderInfo.provider.fullName} ${
          psvOrderInfo.provider.providerID
            ? `(${psvOrderInfo.provider.providerID})`
            : ""
        }`
      : "";

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle={providerInfo}
        crumbs={[
          { name: "PSV Orders", path: "/admin/psv-orders" },
          {
            name: psvOrderInfo ? psvOrderInfo.orderID : null,
          },
        ]}
      />

      <Card>
        <Card.Body>
          {psvOrderInfo &&
          !loadingPsvOrderDetail &&
          psvOrderitemsList.length > 0 ? (
            <div className="float-end space-10">
              <Button
                variant="link"
                size="sm"
                onClick={handlerImportResults}
                disabled={importing}
              >
                <CiImport title="Import Results" size={20} />
                Import Result from IK
              </Button>

              <Button
                variant="link"
                size="sm"
                onClick={handlerPushOrder}
                disabled={pushing}
              >
                <CiExport title="Push Order" size={20} />
                Push Order to IK
              </Button>

              {/* <DownloadPsvFile handlerDownloadPSV={handlerDownloadPSV} /> */}
            </div>
          ) : null}

          <PiDataTable
            columns={columns}
            data={psvOrderitemsList}
            count={psvOrderitemsList.length}
            responsive
            striped={true}
            progressPending={loadingPsvOrderDetail}
            highlightOnHover
            persistTableHead={true}
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

PsvOrderDetail.propTypes = {
  getPsvOrderDetailByOrderId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  psvOrderInfo: state.psvOrderDetail.psvOrderInfo,
  psvOrderitemsList: state.psvOrderDetail.psvOrderitemsList,
  loadingPsvOrderDetail: state.psvOrderDetail.loadingPsvOrderDetail,
  sortingParams: state.psvOrderDetail.sortingParams,
});

export default connect(mapStateToProps, {
  getPsvOrderDetailByOrderId,
  resetComponentStore,
  pushOrderToInstaKnow,
  importPsvResultFromInstaknow,
})(PsvOrderDetail);
