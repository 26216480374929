import axios from "axios";
import { setAlert } from "./alert";
import { logout } from "./auth";

import {
  loadingPsvOrderDetail,
  resetPsvOrderDetail,
  psvOrderDetailError,
  psvOrderDetailUpdated,
} from "reducers/psvOrderDetailReducer";

export const getPsvOrderDetailByOrderId = (order_id) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingPsvOrderDetail());
    const res = await axios.get(`/api/admin/psv-order/${order_id}`, config);
    dispatch(psvOrderDetailUpdated(res.data.response));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          psvOrderDetailError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const importPsvResultFromInstaknow = (order_id) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    // dispatch(loadingPsvOrderDetail());
    const res = await axios.post(
      `/api/admin/psv-order/${order_id}/import-order-result`,
      config
    );

    if (res.data.status === true) {
      dispatch(setAlert(res.data.message, "success"));
    }

    return res.data ? res.data.response : { status: false };
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          psvOrderDetailError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetPsvOrderDetail());
};
