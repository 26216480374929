import React from "react";
import { PropTypes } from "prop-types";
import { Card, Button, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  BiLogInCircle,
  BiPlusMedical,
  BiTrash,
  BiUpload,
} from "react-icons/bi";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import PiDataTable from "views/DataTable/PiDataTable";

import {
  getProvidersList,
  resetComponentStore,
  deleteProvider,
} from "actions/providerActions";

import ProviderFilters from "./ProviderFilters";
import FullAddress from "./FullAddress";
import ProvidersCsvBulkUpload from "./ProvidersCsvBulkUpload";

const ProvidersList = ({
  providerList: { data, count },
  getProvidersList,
  loadingProviderList,
  resetComponentStore,
  sortingParams,
  deleteProvider,
}) => {
  // const navigate = useNavigate();

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: {
      type: {
        value: "individual",
        type: "String",
      },
    },
    filters: ["type"],
  };

  const { page, limit } = sortingParams;

  const [clientParams, setClientParams] = React.useState(initialSortingParams);
  const [onlyOnce, setOnce] = React.useState(true);

  const [modal, setModal] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const [selectedOption, setSelectedOption] = React.useState({
    value: "individual",
    label: "Individuals",
  });

  const onClickCsvUploadHandler = () => {
    setModal(true);
    setDisabled(!isDisabled);
  };

  const onFilterChange = (newParams) => {
    setClientParams((params) => ({ ...params, ...newParams }));
  };

  const onUploadSuccess = () => {
    setSelectedOption({
      value: "all",
      label: "All",
    });
    setClientParams((params) => ({
      ...params,
      query: {
        ...params.query,
        type: {},
      },
      filters: [],
    }));
  };

  const actions = (
    <div className="page-actions">
      <Link to="/admin/professionals/create" title="create">
        <Button color="primary" size="sm">
          <BiPlusMedical /> Create New Professional
        </Button>
      </Link>

      <Button
        variant="primary"
        size="sm"
        className="csv-upload-providers ms-2"
        onClick={onClickCsvUploadHandler}
      >
        <BiUpload /> Bulk Upload
      </Button>

      <ProvidersCsvBulkUpload
        modal={modal}
        setModal={setModal}
        isDisabled={isDisabled}
        setDisabled={setDisabled}
        onUploadSuccess={onUploadSuccess}
      />
    </div>
  );

  const columns = [
    {
      name: "Name",
      selector: (row) => (
        <div className="provider-info">
          {row.type === "organization" ? (
            <React.Fragment>
              {row.orgName}
              <span className="org-type-tag bg-o" title="Organization">
                Organization
              </span>
              <br />
              {row.ProviderID}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <React.Fragment>{row.firstName}</React.Fragment>{" "}
              {row.middleName ? (
                <React.Fragment>{row.middleName}</React.Fragment>
              ) : null}{" "}
              <React.Fragment>{row.lastName}</React.Fragment>
              <span className="org-type-tag bg-i" title="Individual">
                Individual
              </span>
              <br />
              {row.ProviderID}
              <br />
              {row.dateOfBirth ? (
                <React.Fragment>
                  {moment(row.dateOfBirth).format("MM/DD/YYYY")}
                </React.Fragment>
              ) : null}
            </React.Fragment>
          )}
        </div>
      ),
      sortable: false,
      width: "20%",
    },

    {
      name: "Address",
      selector: (row) => <FullAddress row={row} />,
      sortable: false,
      width: "20%",
    },

    {
      name: "Email",
      cell: (row) => row.email,
      sortable: false,
      width: "20%",
    },

    {
      name: "Phone",
      cell: (row) => row.phone,
      sortable: false,
      width: "20%",
    },

    {
      name: "Actions",
      width: "calc(20% - 48px)",
      button: true,
      cell: (row) => (
        <div className="App table-list-buttons">
          <Link
            to={`/admin/professionals/${row._id}/professional-dashboard`}
            title="Professional Dashboard"
          >
            <Button variant="primary" size="sm">
              <BiLogInCircle />
            </Button>
          </Link>

          <Button
            className="ml-1"
            size="sm"
            type="button"
            variant="danger"
            onClick={(e) => {
              if (
                window.confirm(
                  `Are you sure you want to delete: ${row.firstName} ${row.lastName}?`
                )
              ) {
                deleteProvider(row._id);
              }
            }}
          >
            <BiTrash />
          </Button>
        </div>
      ),
    },
  ];

  React.useMemo(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getProvidersList(clientParams);
  }, [getProvidersList, clientParams, resetComponentStore]);

  const handleTableChange = (type, searchText) => {
    let params = {
      limit: limit,
      page: type === "search" ? 1 : page ? page : 1,
    };

    let filters = [];
    if (type === "search") {
      if (searchText.length > 0) {
        filters =
          sortingParams.filters && sortingParams.filters.includes(type)
            ? sortingParams.filters
            : [...sortingParams.filters, type];
        params = {
          ...params,
          query: {
            ...sortingParams.query,
            [type]: {
              firstName: {
                value: searchText,
                type: "String",
              },
              ProviderID: {
                value: searchText,
                type: "String",
              },
              lastName: {
                value: searchText,
                type: "String",
              },
              city: {
                value: searchText,
                type: "String",
              },
              state: {
                value: searchText,
                type: "String",
              },
              zipcode: {
                value: searchText,
                type: "String",
              },
              email: {
                value: searchText,
                type: "String",
              },
              phone: {
                value: searchText,
                type: "String",
              },
            },
          },
          filters,
        };
      } else {
        filters = sortingParams.filters.filter((item) => item !== type);
        const temp = {};
        params = {
          ...sortingParams,
          filters,
        };
        for (var i in params.query) {
          if (i === type) continue;
          temp[i] = params.query[i];
        }
        params.query = temp;
      }
    }

    setClientParams(params);
  };

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle="Professionals"
        crumbs={[{ name: "Professionals" }]}
      />

      <Card>
        <Card.Body>
          {actions}

          <div className="table-filter-section">
            <Row>
              <Col md="6">
                <ProviderFilters
                  type="search"
                  onSearch={handleTableChange}
                  filterType="String"
                  filterParams={clientParams}
                  onFilterChange={onFilterChange}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                />
              </Col>

              <Col md="4">
                <ProviderFilters
                  type="dropdown"
                  onSearch={handleTableChange}
                  filter="type"
                  filterName="Professional Type"
                  filterType="String"
                  filterParams={clientParams}
                  onFilterChange={onFilterChange}
                  options={[
                    {
                      value: "all",
                      label: "All",
                    },
                    {
                      value: "individual",
                      label: "Individuals",
                    },
                    {
                      value: "organization",
                      label: "Organizations",
                    },
                  ]}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                />
              </Col>
            </Row>
          </div>

          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={clientParams}
            setParams={setClientParams}
            pagination
            responsive
            striped={true}
            selectableRows
            progressPending={loadingProviderList}
            highlightOnHover
            persistTableHead={true}
            paginationServer
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

ProvidersList.propTypes = {
  getProvidersList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  providerList: state.provider.providerList,
  loadingProviderList: state.provider.loadingProviderList,
  sortingParams: state.provider.sortingParams,
});

export default connect(mapStateToProps, {
  getProvidersList,
  resetComponentStore,
  deleteProvider,
})(ProvidersList);
