import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingProvidersList,
  providerSearchParametersUpdate,
  providerListUpdated,
  resetProvider,
  providerError,
  loadingOnProviderSubmit,
  providerUpdated,
  providerDetailsById,
  providerDeleted,
  providerStateList,
  loadingOrganizationsList,
  organizationsListUpdated,
} from "reducers/providerReducer";

export const getProvidersList = (providerParams) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = providerParams.query ? providerParams.query : "";
    providerParams.query = query;
    config.params = providerParams;

    dispatch(loadingProvidersList());
    const res = await axios.get("/api/admin/providers/list", config);

    dispatch(providerSearchParametersUpdate(providerParams));

    dispatch(providerListUpdated(res.data.response[0]));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          providerError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const getOrganizationsList = () => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingOrganizationsList());
    const res = await axios.get("/api/admin/providers/organizations", config);

    dispatch(organizationsListUpdated(res.data.response));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          providerError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const getProviderById = (provider_id) => async (dispatch) => {
  dispatch(removeErrors());
  dispatch(loadingOnProviderSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`/api/admin/providers/${provider_id}`, config);

    dispatch(providerDetailsById(res.data.response));
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    console.log(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          providerError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const createProvider = (formData, navigate) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingOnProviderSubmit());

    const res = await axios.post("/api/admin/providers", formData, config);

    if (res.data.status === true) {
      dispatch(setAlert("Professional Created.", "success"));
      navigate(`/admin/professionals`);

      return res.data;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(providerError());
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          providerError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const updateProvider =
  (formData, navigate, provider_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.put(
        `/api/admin/providers/${provider_id}/edit`,
        formData,
        config
      );

      if (res.data.status === true) {
        await dispatch(providerUpdated(res.data.response));
        dispatch(setAlert(res.data.message, "success"));
        navigate(`/admin/professionals/${provider_id}/professional-dashboard`);
        return res.data ? res.data : { status: false };
      } else {
        const errors = res.data.errors;
        dispatch(setAlert(res.data?.message, "danger"));

        if (errors.length) {
          dispatch(providerError());
          dispatch(setErrors(errors));
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch(
            providerError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

export const deleteProvider = (provider_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios.delete(`/api/admin/providers/${provider_id}`, config);

    dispatch(providerDeleted(provider_id));
    dispatch(setAlert("Professional deleted", "success"));
  } catch (err) {
    console.log(err);
    err.response &&
      dispatch(
        providerError({
          msg: err.response.statusText,
          status: err.response.status,
        })
      );
  }
};

export const bulkUpload = (csvData, navigate) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      "/api/admin/providers/bulk-upload",
      csvData,
      config
    );

    if (res.data.status === true) {
      dispatch(
        setAlert(
          "Bulk uploading initiated. You will get an email report in a while.",
          "success"
        )
      );
      navigate(`/admin/professionals`);
      return res.data;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(providerError());
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          providerError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

// reset errors
export const removeProviderErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetProvider());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(providerError());
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/professionals");
};

export const getStatesList = () => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    var res = await axios.get(`/api/common/state/list`, config);

    if (res.data.status === true) {
      await dispatch(providerStateList(res.data.response));
    }
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    console.log(err);
  }
};
