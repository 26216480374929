import React from "react";
import { connect } from "react-redux";
import { Modal, Form, Button } from "react-bootstrap";
import Errors from "Notifications/Errors";

import {
  updateAppToken,
  removeAppTokenErrors,
  setErrors,
} from "actions/appTokenActions";

import { validateForm } from "utils/validation";

const EditAppTokenModal = ({
  modal,
  setModal,
  errorList,
  selectedToken,
  updateAppToken,
  setSelectedToken,
  removeAppTokenErrors,
  setErrors,
}) => {
  const reset = () => {
    setModal(false);
    setSelectedToken({});
  };

  const initialFormData = {
    allowedIPs: "",
    label: "",
  };

  const [formData, setFormData] = React.useState(initialFormData);

  React.useEffect(() => {
    if (!selectedToken) return;
    const { client, allowedIPs, label } = selectedToken;

    const data = {
      client,
      allowedIPs,
      label,
    };
    setFormData((formData) => ({ ...formData, ...data }));
  }, [selectedToken]);

  const { client, allowedIPs, label } = formData;

  const [loading, setLoading] = React.useState(false);

  const onChange = (e) => {
    if (!e.target) return;
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    removeAppTokenErrors();

    let validationRules = [
      {
        param: "label",
        msg: "Label is required",
      },
    ];

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    setLoading(true);
    updateAppToken(formData, selectedToken._id).then((res) => {
      setLoading(false);
      if (res && res.status) {
        setModal(false);
      }
    });
  };
  return (
    <Modal show={modal} onHide={reset} backdrop="static">
      <Modal.Header closeButton className="modal-header">
        <h4>App Token Information</h4>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Form onSubmit={(e) => onSubmit(e)}>
          <Form.Group className="form-group">
            <Form.Label htmlFor="client">
              Client <span>*</span>
            </Form.Label>
            <br></br>
            <Form.Control
              className={errorList.label ? "invalid" : ""}
              type="text"
              id="client"
              name="client"
              maxLength="100"
              value={client}
              disabled="true"
            />
            <Errors current_key="client" key="client" />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label htmlFor="label">
              Label <span>*</span>
            </Form.Label>
            <Form.Control
              className={errorList.label ? "invalid" : ""}
              type="text"
              id="label"
              name="label"
              value={label}
              onChange={(e) => onChange(e)}
            />

            <Errors current_key="label" key="label" />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label htmlFor="allowedIPs">Allowed IPs</Form.Label>
            <Form.Control
              className={errorList.allowedIPs ? "invalid" : ""}
              as="textarea"
              rows={5}
              id="allowedIPs"
              name="allowedIPs"
              value={allowedIPs}
              onChange={(e) => onChange(e)}
            />
            <small className="float-end">
              Note: Put multiple IPs with Comma (,) separated.
            </small>
          </Form.Group>

          <div className="app-token-modal-footer">
            <Button
              className="m-2"
              type="submit"
              variant="primary"
              disabled={loading}
            >
              {loading ? (
                <>
                  <span className="spinner-border spinner-border-sm"></span>
                  {` Loading... `}
                </>
              ) : (
                <>Save</>
              )}
            </Button>
            <Button
              className="ml-2"
              type="reset"
              variant="danger"
              onClick={reset}
              disabled={loading}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
});

export default connect(mapStateToProps, {
  updateAppToken,
  removeAppTokenErrors,
  setErrors,
})(EditAppTokenModal);
