import Dashboard from "views/Admin/Dashboard";
import NotFoundInner from "views/404Inner";

import UsersList from "views/Admin/Users/UsersList";
import CreateUser from "views/Admin/Users/AddUser/CreateUser";
import ViewUser from "views/Admin/Users/ViewUser";

import CredentialsList from "views/Admin/Credential/CredentialsList";
import CreateCredential from "views/Admin/Credential/CreateCredential";
import EditCredential from "views/Admin/Credential/EditCredential";

import AdminProfile from "views/Admin/Profile/AdminProfile";
import EditProfile from "views/Admin/Profile/EditProfile";

import SchoolsList from "views/Admin/Schools/SchoolsList";
import CreateSchool from "views/Admin/Schools/CreateSchool";
import EditSchool from "views/Admin/Schools/EditSchool";

import DegreesList from "views/Admin/Schools/Degree/DegreesList";
import CreateDegree from "views/Admin/Schools/Degree/CreateDegree";
import EditDegree from "views/Admin/Schools/Degree/EditDegree";

import TaxonomiesList from "views/Admin/Taxonomies/TaxonomiesList";
import CreateTaxonomy from "views/Admin/Taxonomies/CreateTaxonomy";
import EditTaxonomy from "views/Admin/Taxonomies/EditTaxonomy";

import SourcesList from "views/Admin/Source/SourcesList";
import CreateSource from "views/Admin/Source/CreateSource";
import EditSource from "views/Admin/Source/EditSource";

import SpecialtiesList from "views/Admin/Specialty/SpecialtiesList";
import CreateSpecialty from "views/Admin/Specialty/CreateSpecialty";
import EditSpecialty from "views/Admin/Specialty/EditSpecialty";

import ProvidersList from "views/Admin/Provider/ProvidersList";
import CreateProvider from "views/Admin/Provider/CreateProvider";
import EditProvider from "views/Admin/Provider/EditProvider";
import ProviderInfo from "views/Admin/Provider/ProviderDashboard/index";

import PsvOrdersList from "views/Admin/Psv/PsvOrdersList";
import PsvOrderDetail from "views/Admin/Psv/PsvOrderDetail";
import PsvReadyList from "views/Admin/PsvReady/PsvReadyList";
import CreateOneDriveToken from "views/Admin/CreateOneDriveToken";

import AppTokensList from "views/Admin/AppTokens/AppTokensList";
import CreateAppToken from "views/Admin/AppTokens/CreateAppToken";

const AdminRoutes = [
  {
    path: "/dashboard",
    name: "Admin Dashboard",
    element: <Dashboard />,
    provider: false,
  },
  {
    path: "/users",
    name: "All Users",
    element: <UsersList />,
    provider: false,
  },
  {
    path: "/users/create",
    name: "Add User",
    element: <CreateUser />,
    provider: false,
  },
  {
    path: "/users/:user_id/*",
    name: "View User",
    element: <ViewUser />,
    provider: false,
  },
  {
    path: "/credentials",
    name: "Credentials",
    element: <CredentialsList />,
    provider: false,
  },
  {
    path: "/credentials/create",
    name: "Add Credential",
    element: <CreateCredential />,
    provider: false,
  },
  {
    path: "/credentials/:credential_id/edit",
    name: "Edit Credential",
    element: <EditCredential />,
    provider: false,
  },
  {
    path: "/profile",
    name: "Admin Profile",
    element: <AdminProfile />,
    provider: false,
  },
  {
    path: "/profile/edit",
    name: "Edit Profile",
    element: <EditProfile />,
    provider: false,
  },
  {
    path: "/schools",
    name: "Schools",
    element: <SchoolsList />,
    provider: false,
  },
  {
    path: "/schools/create",
    name: "Add School",
    element: <CreateSchool />,
    provider: false,
  },
  {
    path: "/schools/:school_id/*",
    name: "View School",
    element: <EditSchool />,
    provider: false,
  },
  {
    path: "/degrees",
    name: "Degrees",
    element: <DegreesList />,
    provider: false,
  },
  {
    path: "/degrees/create",
    name: "Add Degree",
    element: <CreateDegree />,
    provider: false,
  },
  {
    path: "/degrees/:degree_id/*",
    name: "View Degree",
    element: <EditDegree />,
    provider: false,
  },
  {
    path: "/taxonomies",
    name: "Taxonomies",
    element: <TaxonomiesList />,
    provider: false,
  },
  {
    path: "/taxonomies/create",
    name: "Add Taxonomy",
    element: <CreateTaxonomy />,
    provider: false,
  },
  {
    path: "/taxonomies/:taxonomy_id/edit",
    name: "View Taxonomy",
    element: <EditTaxonomy />,
    provider: false,
  },
  {
    path: "/sources",
    name: "Sources",
    element: <SourcesList />,
    provider: false,
  },
  {
    path: "/sources/create",
    name: "Add Source",
    element: <CreateSource />,
    provider: false,
  },
  {
    path: "/sources/:source_id/edit",
    name: "View Source",
    element: <EditSource />,
    provider: false,
  },
  {
    path: "/specialties",
    name: "Specialties",
    element: <SpecialtiesList />,
    provider: false,
  },
  {
    path: "/specialties/create",
    name: "Add Specialty",
    element: <CreateSpecialty />,
    provider: false,
  },
  {
    path: "/specialties/:specialty_id/edit",
    name: "View Specialty",
    element: <EditSpecialty />,
    provider: false,
  },
  {
    path: "/professionals",
    name: "Professionals",
    element: <ProvidersList />,
    provider: false,
  },
  {
    path: "/professionals/create",
    name: "Add Professional",
    element: <CreateProvider />,
    provider: false,
  },
  {
    path: "/professionals/:provider_id/edit",
    name: "View Professional",
    element: <EditProvider />,
    provider: true,
  },
  {
    path: "/professionals/:provider_id/professional-dashboard",
    name: "Professional Dashboard",
    element: <ProviderInfo />,
    provider: true,
  },
  {
    path: "/psv-orders",
    name: "PSV Orders",
    element: <PsvOrdersList />,
    provider: false,
  },
  {
    path: "/psv-orders/:order_id",
    name: "PSV Order Detail",
    element: <PsvOrderDetail />,
    provider: false,
  },
  {
    path: "/psv-ready",
    name: "PSV Ready",
    element: <PsvReadyList />,
    provider: false,
  },
  {
    path: "/create-onedrive-token",
    name: "Create OneDrive Token",
    element: <CreateOneDriveToken />,
    provider: false,
  },
  {
    path: "/app-tokens",
    name: "App Tokens",
    element: <AppTokensList />,
    provider: false,
  },
  {
    path: "/app-tokens/create",
    name: "Add App Token",
    element: <CreateAppToken />,
  },
  {
    path: "/*",
    element: <NotFoundInner />,
    provider: true,
  },
];

export default AdminRoutes;
