import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingOnProviderSpecialtySubmit,
  loadingProviderSpecialtiesList,
  providerSpecialtiesListUpdated,
  providerSpecialtyCreated,
  providerSpecialtyDeleted,
  providerSpecialtyDetailsById,
  providerSpecialtyError,
  providerSpecialtySearchParametersUpdate,
  providerSpecialtyUpdated,
  resetProviderSpecialty,
  specialtiesList,
  subSpecialtiesList,
} from "reducers/providerSpecialtyReducer";

export const getSpecialtiesList = () => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };

    const res = await axios.get(
      "/api/admin/providers/specialties/list",
      config
    );

    if (res.data.status === true) {
      await dispatch(
        specialtiesList(res.data && res.data.response ? res.data.response : [])
      );
    }
    return res.data && res.data.response
      ? res.data.response
      : { status: false };
  } catch (err) {
    console.log(err);
  }
};

export const getSubSpecialtiesList = () => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };

    const res = await axios.get(
      "/api/admin/providers/sub-specialties/list",
      config
    );

    if (res.data.status === true) {
      await dispatch(
        subSpecialtiesList(
          res.data && res.data.response ? res.data.response : []
        )
      );
    }
    return res.data && res.data.response
      ? res.data.response
      : { status: false };
  } catch (err) {
    console.log(err);
  }
};

export const getProviderSpecialtiesList =
  (providerParams, provider_id) => async (dispatch) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };

      const query = providerParams.query ? providerParams.query : "";
      providerParams.query = query;
      config.params = providerParams;

      dispatch(loadingProviderSpecialtiesList());
      const res = await axios.get(
        `/api/admin/providers/${provider_id}/specialties`,
        config
      );

      dispatch(providerSpecialtySearchParametersUpdate(providerParams));

      dispatch(providerSpecialtiesListUpdated(res.data.response[0]));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            providerSpecialtyError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const createProviderSpecialty =
  (formData, navigate, provider_id) => async (dispatch) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };

      dispatch(loadingOnProviderSpecialtySubmit());

      const res = await axios.post(
        `/api/admin/providers/${provider_id}/specialties/create`,
        formData,
        config
      );

      if (res.data.status === true) {
        dispatch(providerSpecialtyCreated(res.data.response));
        dispatch(setAlert("Provider Specialty Created.", "success"));
        navigate(`/admin/providers/${provider_id}/professional-dashboard`);

        return res.data;
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch(providerSpecialtyError());
          dispatch(setAlert(res.data.message, "danger"));

          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            providerSpecialtyError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const getProviderSpecialtyById =
  (provider_id, provider_specialty_id) => async (dispatch) => {
    dispatch(removeErrors());
    dispatch(loadingOnProviderSpecialtySubmit());
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.get(
        `/api/admin/providers/${provider_id}/specialties/${provider_specialty_id}`,
        config
      );

      dispatch(providerSpecialtyDetailsById(res.data.response));
      return res.data ? res.data.response : { status: false };
    } catch (err) {
      console.log(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            providerSpecialtyError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const updateProviderSpecialty =
  (formData, navigate, provider_id, provider_specialty_id) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.put(
        `/api/admin/providers/${provider_id}/specialties/${provider_specialty_id}/edit`,
        formData,
        config
      );

      if (res.data.status === true) {
        await dispatch(providerSpecialtyUpdated(res.data.response));
        dispatch(setAlert(res.data.message, "success"));
        return res.data ? res.data : { status: false };
      } else {
        const errors = res.data.errors;
        dispatch(setAlert(res.data?.message, "danger"));

        if (errors.length) {
          dispatch(providerSpecialtyError());
          dispatch(setErrors(errors));
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch(
            providerSpecialtyError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

export const deleteProviderSpecialty =
  (provider_id, provider_specialty_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios.delete(
        `/api/admin/providers/${provider_id}/specialties/${provider_specialty_id}`,
        config
      );

      dispatch(providerSpecialtyDeleted(provider_specialty_id));
      dispatch(setAlert("Provider Specialty deleted", "success"));
    } catch (err) {
      console.log(err);
      err.response &&
        dispatch(
          providerSpecialtyError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );
    }
  };

export const resetProviderSpecialtyComponentStore = () => async (dispatch) => {
  await dispatch(resetProviderSpecialty());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(providerSpecialtyError());
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

export const removeProviderSpecialtyErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};
