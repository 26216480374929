import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
// import { BiTrash } from "react-icons/bi";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import PiDataTable from "views/DataTable/PiDataTable";

import {
  getPsvReadyList,
  resetComponentStore,
  // deletePsvOrder,
} from "actions/psvReadyActions";

import PsvNav from "./PsvNav";
import PsvItems from "./PsvItems";
import PsvReadyOrderModal from "./PsvReadyOrderModal";
import PsvReadyFilters from "./PsvReadyFilters";

const PsvReadyList = ({
  psvReadyList: { data, count },
  getPsvReadyList,
  loadingPsvReadyList,
  resetComponentStore,
  sortingParams,
  // deletePsvOrder,
}) => {
  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const { page, limit } = sortingParams;

  const [modal, setModal] = React.useState(false);

  const [clientParams, setClientParams] = React.useState(initialSortingParams);
  const [onlyOnce, setOnce] = React.useState(true);

  const [selectedItems, setSelectedItems] = React.useState([]);
  const [selectedProviders, setSelectedProviders] = React.useState([]);

  const reloadItems = () => {
    getPsvReadyList(clientParams);
  };

  const onChangeHandler = (e, selectedRow) => {
    const checked = e.target.checked;

    setSelectedProviders((prev) => {
      const providersSelected = [...prev];

      const providerIndex = providersSelected.findIndex(
        (each) => each["_id"] === selectedRow["_id"]
      );

      if (checked && providerIndex === 1) {
        return [...providersSelected, selectedRow];
      }

      if (providerIndex === -1) {
        return [...providersSelected, selectedRow];
      }

      providersSelected.splice(providerIndex, 1);

      return providersSelected;
    });
  };

  const columns = [
    {
      name: "",
      selector: (row) => {
        const isProviderSelected = selectedItems.some(
          (item) => item["ProviderID"].toString() === row["_id"].toString()
        );

        return (
          <React.Fragment>
            <input
              type="checkbox"
              checked={isProviderSelected}
              onChange={(e) => onChangeHandler(e, row)}
            />
          </React.Fragment>
        );
      },
      sortable: false,
      width: "48px",
    },
    {
      name: "Professional",
      selector: (row) => (
        <React.Fragment>
          {row.type === "organization"
            ? `${row.orgName}`
            : `${row.firstName} ${row.lastName}`}
          <br />
          {row.ProviderID}
          <br />
          <Link to={`/admin/professionals/${row._id}/professional-dashboard`}>
            View Professional
          </Link>
        </React.Fragment>
      ),
      sortable: false,
      width: "15%",
    },

    {
      name: "Credentials to be verified",
      selector: (row) => (
        <React.Fragment>
          <PsvItems
            provider={row}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            selectedProviders={selectedProviders}
            setSelectedProviders={setSelectedProviders}
          />
        </React.Fragment>
      ),
      sortable: false,
      width: "calc(85% - 48px)",
    },
  ];

  React.useMemo(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getPsvReadyList(clientParams);
  }, [getPsvReadyList, clientParams, resetComponentStore]);

  const onClickHandle = () => {
    setModal(true);
  };

  const onFilterHandleTableChange = (type, searchText) => {
    let params = {
      limit: limit,
      page: type === "search" ? 1 : page ? page : 1,
    };

    let filters = [];

    if (type === "search") {
      if (searchText > 0) {
        filters = sortingParams.filters.includes(type)
          ? sortingParams.filters
          : [...sortingParams.filters, type];

        params = {
          ...params,
          query: {
            ...sortingParams.query,
            [type]: {
              expireDays: {
                value: searchText,
                type: "Number",
              },
            },
          },
          filters,
        };
      } else {
        filters = sortingParams.filters.filter((item) => item !== type);
        const temp = {};
        params = {
          ...sortingParams,
          filters,
        };
        for (var i in params.query) {
          if (i === type) continue;
          temp[i] = params.query[i];
        }
        params.query = temp;
      }
    }

    setClientParams(params);
  };

  const onFilterChange = (newParams) => {
    setClientParams((params) => ({ ...params, ...newParams }));
  };

  return (
    <React.Fragment>
      <PsvReadyOrderModal
        modal={modal}
        setModal={setModal}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        reloadItems={reloadItems}
      />

      <AppBreadcrumb pageTitle="PSV Ready" crumbs={[{ name: "PSV Ready" }]} />

      <Card>
        <Card.Body>
          <PsvNav current={"psv-ready"} />

          <div className="table-filter-section">
            <Row className="align-items-end justify-content-between">
              <Col md="6" className="psv_ready_filter_options">
                <Row className="d-ruby">
                  <Col lg="4">
                    <PsvReadyFilters
                      type="checkbox"
                      onSearch={onFilterHandleTableChange}
                      filter="unverified"
                      filterName="Unverified only"
                      filterType="Boolean"
                      filterParams={clientParams}
                      onFilterChange={onFilterChange}
                    />
                  </Col>

                  <Col lg="8">
                    <PsvReadyFilters
                      type="search"
                      filter="search"
                      onSearch={onFilterHandleTableChange}
                      filterType="Number"
                      filterParams={clientParams}
                      onFilterChange={onFilterChange}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="2" className="d-flex align-self-end justify-content-end">
                <Button
                  onClick={onClickHandle}
                  disabled={!selectedItems.length}
                >
                  Create Order
                </Button>
              </Col>
            </Row>
          </div>

          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={clientParams}
            setParams={setClientParams}
            pagination
            responsive
            striped={true}
            // selectableRows
            // onSelectedRowsChange={handleSelectedRows}
            progressPending={loadingPsvReadyList}
            highlightOnHover
            persistTableHead={true}
            paginationServer
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

PsvReadyList.propTypes = {
  getPsvReadyList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  psvReadyList: state.psvReady.psvReadyList,
  loadingPsvReadyList: state.psvReady.loadingPsvReadyList,
  sortingParams: state.psvReady.sortingParams,
});

export default connect(mapStateToProps, {
  getPsvReadyList,
  resetComponentStore,
  // deletePsvOrder,
})(PsvReadyList);
