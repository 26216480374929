import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingAppTokensList,
  appTokenListUpdated,
  appTokenSearchParametersUpdate,
  resetAppToken,
  appTokenStatusUpdated,
  appTokenError,
  loadingOnAppTokenSubmit,
  appTokenCreated,
  appTokenUpdated,
  clientsListUpdated,
} from "reducers/appTokenReducer";

export const getAppTokensList = (appTokenParams) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = appTokenParams.query ? appTokenParams.query : "";
    appTokenParams.query = query;
    config.params = appTokenParams;

    dispatch(loadingAppTokensList());
    const res = await axios.get("/api/admin/app-tokens/list", config);

    dispatch(appTokenSearchParametersUpdate(appTokenParams));

    dispatch(appTokenListUpdated(res.data.response[0]));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          appTokenError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const createAppToken = (formData) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingOnAppTokenSubmit());

    const res = await axios.post("/api/admin/app-tokens", formData, config);
    if (res.data.status === true) {
      dispatch(appTokenCreated(res.data.response));

      return res.data;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(appTokenError());
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          appTokenError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const changeTokenStatus = (app_token_id, status) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.put(
      `/api/admin/app-tokens/${app_token_id}/update-status`,
      { status },
      config
    );

    if (res.data.status === true) {
      await dispatch(
        appTokenStatusUpdated({
          app_token_id,
          status,
        })
      );
      dispatch(setAlert(res.data.message, "success"));
      return res.data ? res.data : { status: false };
    } else {
      const errors = res.data.errors;
      if (errors.length) {
        dispatch(appTokenError());
        dispatch(setAlert(errors[0].msg, "danger"));
      }
    }
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch(
          appTokenError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

export const updateAppToken = (formData, app_token_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.put(
      `/api/admin/app-tokens/${app_token_id}`,
      formData,
      config
    );

    if (res.data.status === true) {
      await dispatch(appTokenUpdated(res.data.response));
      dispatch(setAlert(res.data.message, "success"));
      return res.data ? res.data : { status: false };
    } else {
      const errors = res.data.errors;
      if (errors.length) {
        dispatch(appTokenError());
        dispatch(setErrors(errors));
      }
    }
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch(
          appTokenError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

// reset errors
export const removeAppTokenErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetAppToken());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(appTokenError());
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/app-tokens");
};
