import React from "react";
import { PropTypes } from "prop-types";
import { Card, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FaRegEye, FaCalendarDay } from "react-icons/fa";
import moment from "moment";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import PiDataTable from "views/DataTable/PiDataTable";

import { getPsvOrdersList, resetComponentStore } from "actions/psvOrderActions";

import PsvNav from "../PsvReady/PsvNav";
import PsvOrderFilter from "./PsvOrderFilters";

const PsvOrdersList = ({
  psvOrdersList: { data, count },
  getPsvOrdersList,
  loadingPsvOrdersList,
  resetComponentStore,
  sortingParams,
}) => {
  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const { page, limit } = sortingParams;

  const [clientParams, setClientParams] = React.useState(initialSortingParams);
  const [onlyOnce, setOnce] = React.useState(true);

  const columns = [
    {
      name: "Order",
      selector: (row) => (
        <div className="py-1">
          {row.orderID}
          <div title="Order Created At">
            <FaCalendarDay />{" "}
            <span>{moment(row.createdAt).format("MM/DD/YYYY")}</span>
          </div>
        </div>
      ),
      sortable: false,
      width: "20%",
    },

    {
      name: "Professional",
      selector: (row) => (
        <div className="py-1">
          {row.provider.fullName}
          <br />
          {row.provider.providerID}
          <br />
          <Link
            to={`/admin/professionals/${row.provider._id}/professional-dashboard`}
          >
            View Professional
          </Link>
        </div>
      ),
      sortable: false,
      width: "25%",
    },

    {
      name: "# PSV Items",
      selector: (row) => (
        <Link to={`/admin/psv-orders/${row._id}`} title="PSV Order Items">
          {row.itemCount}
        </Link>
      ),
      sortable: false,
      width: "20%",
    },

    {
      name: "Status",
      cell: (row) => {
        switch (row.status) {
          case 1:
            return "Open";
          case 2:
            return "Pending";
          case 3:
            return "Partialy Completed";
          case 4:
            return "Completed";
          default:
            return "";
        }
      },
      sortable: false,
      width: "15%",
    },

    {
      name: "Actions",
      width: "20%",
      button: true,
      cell: (row) => (
        <div className="d-flex gap-2">
          <Link to={`/admin/psv-orders/${row._id}`} title="PSV Order Detail">
            <Button variant="primary" size="sm">
              <FaRegEye />
            </Button>
          </Link>
        </div>
      ),
    },
  ];

  React.useMemo(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getPsvOrdersList(clientParams);
  }, [getPsvOrdersList, clientParams, resetComponentStore]);

  const handleTableChange = (type, searchText) => {
    let params = {
      limit: limit,
      page: type === "search" ? 1 : page ? page : 1,
    };

    let filters = [];
    if (type === "search") {
      if (searchText.length > 0) {
        filters = sortingParams.filters.includes(type)
          ? sortingParams.filters
          : [...sortingParams.filters, type];
        params = {
          ...params,
          query: {
            ...sortingParams.query,
            [type]: {
              orderID: {
                value: searchText,
                type: "String",
              },
              "provider.fullName": {
                value: searchText,
                type: "String",
              },
              "provider.providerID": {
                value: searchText,
                type: "String",
              },
              status: {
                value: searchText,
                type: "String",
              },
            },
          },
          filters,
        };
      } else {
        filters = sortingParams.filters.filter((item) => item !== type);
        const temp = {};
        params = {
          ...sortingParams,
          filters,
        };
        for (var i in params.query) {
          if (i === type) continue;
          temp[i] = params.query[i];
        }
        params.query = temp;
      }
    }
    setClientParams(params);
  };

  const onFilterChange = (newParams) => {
    setClientParams((params) => ({ ...params, ...newParams }));
  };

  return (
    <React.Fragment>
      <AppBreadcrumb pageTitle="PSV Orders" crumbs={[{ name: "PSV Orders" }]} />

      <Card>
        <Card.Body>
          <PsvNav current={"psv-orders"} />

          <div className="table-filter-section">
            <Row>
              <Col md="6">
                <PsvOrderFilter
                  type="text"
                  onSearch={handleTableChange}
                  filterType="String"
                  filterParams={clientParams}
                  onFilterChange={onFilterChange}
                />
              </Col>
            </Row>
          </div>

          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={clientParams}
            setParams={setClientParams}
            pagination
            responsive
            striped={true}
            // selectableRows
            progressPending={loadingPsvOrdersList}
            highlightOnHover
            persistTableHead={true}
            paginationServer
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

PsvOrdersList.propTypes = {
  getPsvOrdersList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  psvOrdersList: state.psvOrder.psvOrdersList,
  loadingPsvOrdersList: state.psvOrder.loadingPsvOrdersList,
  sortingParams: state.psvOrder.sortingParams,
});

export default connect(mapStateToProps, {
  getPsvOrdersList,
  resetComponentStore,
})(PsvOrdersList);
