import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingPsvOrdersList,
  psvOrderSearchParametersUpdate,
  psvOrderListUpdated,
  resetPsvOrder,
  psvOrderError,
  loadingOnPsvOrderSubmit,
  psvOrderUpdated,
  psvOrderDetailsById,
  psvOrderDeleted,
} from "reducers/psvOrderReducer";

export const getPsvOrdersList = (psvOrderParams) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = psvOrderParams.query ? psvOrderParams.query : "";
    psvOrderParams.query = query;
    config.params = psvOrderParams;

    dispatch(loadingPsvOrdersList());
    const res = await axios.get("/api/admin/psv-order/list", config);

    dispatch(psvOrderSearchParametersUpdate(psvOrderParams));

    dispatch(psvOrderListUpdated(res.data.response[0]));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          psvOrderError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const deletePsvOrder = (psvOrder_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios.delete(`/api/admin/psv-order/${psvOrder_id}`, config);

    dispatch(psvOrderDeleted(psvOrder_id));
    dispatch(setAlert("PSV Order deleted", "success"));
  } catch (err) {
    // console.log(err);
    err.response &&
      dispatch(
        psvOrderError({
          msg: err.response.statusText,
          status: err.response.status,
        })
      );
  }
};

export const pushOrderToInstaKnow = (psv_order_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `/api/admin/psv-order/${psv_order_id}/push-order`,
      config
    );

    if (res.data?.response) {
      dispatch(
        setAlert(
          res.data.response?.message || "PSV order pushed to Instaknow",
          "success"
        )
      );
    }

    return res.data ? res.data.response : { status: false };
  } catch (err) {
    // console.log(err);
    err.response &&
      dispatch(
        psvOrderError({
          msg: err.response.statusText,
          status: err.response.status,
        })
      );
  }
};

// reset errors
export const removeProviderErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetPsvOrder());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(psvOrderError());
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/psv-orders");
};
